<template>
    <div :class="cn('grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4', props.class)">
        <template v-if="props.itemKey">
            <GridItem v-for="item in visibleItems" :key="item[props.itemKey]">
                <slot name="item" :item="item"></slot>
            </GridItem>
        </template>
        <slot />
    </div>
    <div v-if="!showAll && remainingCount && remainingCount > 0" class="mt-4 flex justify-center">
        <Button variant="link" size="lg" @click="showAll = true">
            <span>{{ $t("PartCategory.ShowMoreManufacturers", [remainingCount]) }}</span>
            <i class="fas fa-caret-down"></i>
        </Button>
    </div>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items?: any[] | null;
    itemKey?: string;
    class?: HTMLAttributes["class"];
    previewLimit?: number;
};

const showAll = ref(false);

const props = withDefaults(defineProps<Props>(), {
    items: () => [],
    itemKey: "Id",
    class: "",
    previewLimit: 8,
});

const visibleItems = computed(() => {
    if (!Array.isArray(props.items)) return [];
    return showAll.value ? props.items : props.items.slice(0, props.previewLimit);
});

const remainingCount = computed(() => {
    return Array.isArray(props.items) && props.items.length - props.previewLimit;
});
</script>
