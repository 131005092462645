<template>
    <div :class="cn('p-4 shadow-lg flex flex-col min-h-28', props.class)">
        <slot />
    </div>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

interface Props {
    class?: HTMLAttributes["class"];
}

const props = defineProps<Props>();
</script>
